import React, { useEffect, useState } from 'react'

function Confide() {
    const [mobile, SetImobile] = useState(window.innerWidth < 507)
    useEffect(() => {
        const hundleSize = () => {
            SetImobile(window.innerWidth < 507)
        }
        window.addEventListener('resize', hundleSize)
        return () => window.removeEventListener('resize', hundleSize)
    }, [])
    return (
        <div class="p-4">
            <div class="bg-white rounded-lg border p-4">
                <h1 class={` font-bold ${mobile ? 'text-[20px]' : 'text-[25px]'} text-blue-800 font-serif text-center w-full`}>Politique de confidentialité de BTRGeoLoc</h1>
                <div class="mt-4">
                    <div class="mb-4">
                        <p className='text-gray-600 leading-7 pl-5 font-normal'>
                            Notre application de géolocalisation BTRGeoLoc respecte votre vie privée
                            et s'engage à protéger les informations personnelles que vous partagez
                            avec nous. Voici notre politique de confidentialité :
                        </p>
                    </div>

                    <div class="mt-10">
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>Collecte d'informations</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>
                                <li className='mb-2 '>
                                    L'application collecte et utilise votre position géographique actuelle
                                    pour vous fournir des services de localisation.
                                </li>

                                <li className='mb-2 '>
                                    Lorsque vous entrez une adresse pour la localiser, nous collectons
                                    temporairement les données nécessaires pour effectuer la recherche
                                    et afficher les résultats.</li>


                            </ul>
                        </p>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`} >Stockage local des localisations</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>
                                <li className='mb-2 '>Votre position actuelle et les adresses recherchées sont stockées
                                    localement sur votre appareil afin de faciliter l'accès ultérieur à ces
                                    informations.</li>
                                <li className='mb-2 '>
                                    Nous ne partageons pas ces données de localisation avec des tiers ni
                                    ne les utilisons à des fins publicitaires.
                                </li>

                            </ul>
                        </p>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>Sécurité des données</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>
                                <li className='mb-2 '>Nous prenons des mesures appropriées pour protéger les
                                    informations collectées contre tout accès non autorisé, utilisation
                                    abusive ou divulgation.</li>
                                <li className='mb-2 '>Veuillez noter que la sécurité des données transmises via Internet ou
                                    stockées électroniquement ne peut être garantie à 100%.
                                </li>

                            </ul>
                        </p>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>Divulgation des informations</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>

                                <li className='mb-2 '>Nous ne divulguons pas vos informations de localisation à des tiers,
                                    sauf si requis par la loi ou si nécessaire pour protéger nos droits
                                    légaux.
                                </li>

                            </ul>
                        </p>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>Mises à jour de la politique de confidentialité </p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>
                                <li className='mb-2 '>Nous pouvons mettre à jour notre politique de confidentialité de
                                    temps à autre. Les changements seront affichés dans l'application et
                                    prendront effet dès leur publication.
                                </li>

                            </ul>
                        </p>

                    </div>
                    <p className='text-gray-500 mt-10 text-center'>
                        En utilisant notre application de géolocalisation, vous acceptez notre
                        politique de confidentialité. Si vous avez des questions ou des
                        préoccupations concernant la confidentialité de vos informations, veuillez
                        nous contacter à l'adresse fournie dans l'application ou sur cet adresse e-mail :{" "}
                        <a href="mailto:btr.dev@burundientempsreel.com" className="text-blue-500">
                            btr.dev@burundientempsreel.com
                        </a>
                    </p>
                </div>
            </div>
        </div>


    )
}

export default Confide
